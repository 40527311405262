<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">
        Bitten wählen Sie den Lagerort für jede Position
      </h1>
    </div>
    <div class="card-content">
      
      <div class="columns" v-for="(w, index) in s.articles" :key="index">
        <div class="column is-9">
          <b>Pos.{{ index + 1 }}, ID {{ w.id }} {{ w.article }}</b
          ><br />
          <span class="smallgrey"
            >{{ w.bottles }} Flasche(n) | Artikelnummer {{ w.wine_id }}-{{
              w.article_id
            }}
            |EK-Preis/Fl. {{ w.price_ek }} €<br />
            Etikett {{ $utilities.getValue("label", w.label) }} | Kapsel
            {{ $utilities.getValue("capsula", w.capsula) }} | Füllstand
            {{ $utilities.getValue("fill_level", w.fill_level) }} | Verpackung
            {{ $utilities.getValue("packaging", w.packaging) }}
          </span>
        </div>
        <div class="column is-3">
          <b-select
            v-model="s.articles[index].stockplace"
            :key="index"     
            default="1"
            :ref="`select${index}`"
            @input="readyToSaveCheck()"
            
          >
            <option
              v-for="option in places"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
          <a @click.prevent="copyOptionToFollowingFields(index, w.id, w.stockplace)" class="very-small">Für Nachfolgende übernehmen</a>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
        <b-button
          v-if="readyToSave"
          type="is-success"
          icon-left="package-variant-closed"
          @click="addToStock2()"
          >Jetzt Einbuchen</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "addToStock",
  props: ["s"],
  data: function() {
    return {
      submitted: false,
      error: false,
      stockplaces: [],
      readyToSave: false

    }
  },
  
  created: function() {
    this.places = this.$utilities.createRackList(
      this.$store.getters.config.stock.racks,
      this.$store.getters.config.stock.traysPerRack
    );
    for (var i = 0; i < this.s.articles.length; i++) {
           //console.log('Ändere '+i+' auf '+ option);
          this.s.articles[i].stockplace = 1
          this.$refs['select'+i][0].selected = 1
        }
        this.readyToSaveCheck();
   
  },

  methods: {
    async addToStock2() {
      this.isLoading = true
      var t = this;
      
      t.$http
        .post("/consignments/addtostock", {
          consignmentSequence: this.s
        })
        .then(function(resp) {
          t.isLoading = false
          //t.$router.push({ path: "/consignments/view/"+resp.data.data });
          t.$parent.close()
          t.$parent.$parent.$parent.consignment = resp.data.data
          t.$parent.$parent.$parent.parseImages()
          t.$parent.$parent.editmode = false

          window.scrollTo(0, 0)

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    },
    copyOptionToFollowingFields(pos, id, option) {
      //console.log(this.$refs['select'+0]);
      
        for (var i = pos + 1; i < this.s.articles.length; i++) {
           //console.log('Ändere '+i+' auf '+ option);
          this.s.articles[i].stockplace = option
          this.$refs['select'+i][0].selected = option
        }
        this.readyToSaveCheck();
      
    },
    readyToSaveCheck: function() {
      //console.log('Check readiness')
      var res = true
      for (var i = 0; i < this.s.articles.length; i++) {
        if (
          typeof this.s.articles[i].stockplace == "undefined" ||
          this.s.articles[i].stockplace == ""
        ) {
          res = false
          break
        }
      }
      this.readyToSave = res;
    }
  }
}
</script>

<style></style>
